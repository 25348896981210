@import '../shared/common.scss';

.SequencerBolt {
  width: 50px;

  @include for-medium-screens {
    width: 35px;
  }
  @include for-small-screens {
    width: 35px;
  }
}
