@import '../shared/common.scss';

@keyframes squiggly-anim {
  0% {
    -webkit-filter: url('#squiggly-0');
    filter: url('#squiggly-0');
  }
  25% {
    -webkit-filter: url('#squiggly-1');
    filter: url('#squiggly-1');
  }
  50% {
    -webkit-filter: url('#squiggly-2');
    filter: url('#squiggly-2');
  }
  75% {
    -webkit-filter: url('#squiggly-3');
    filter: url('#squiggly-3');
  }
  100% {
    -webkit-filter: url('#squiggly-4');
    filter: url('#squiggly-4');
  }
}

.PopupOverlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.75);
}

.Popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;

  @include for-small-screens {
    transform: scale(1.5);
  }

  .outer,
  .inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    > * {
      width: 100%;
    }
  }

  .inner {
    svg.anim {
      animation: squiggly-anim 0.34s linear infinite;
    }

    .pagingButton {
      @extend %hand-cursor-pointer2;
      transition: transform 0.2s ease-in-out;

      .indicator {
        fill: #676767;
        transition: fill 0.1s ease-in-out;
      }

      &.selected {
        .indicator {
          fill: #ffffff;
        }
      }

      &:hover {
        .indicator {
          fill: #ffffff;
        }
      }
    }

    &.shareForm {
      > div {
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        input {
          display: block;
          height: 50px;
          border: 1px solid #fff;
          background-color: #000;
          font-size: 2rem;
          color: #fff;
          margin-bottom: 40px;
          width: 40vw;
          padding-left: 10px;
          padding-right: 10px;

          @include for-small-screens {
            font-size: 1rem;
            height: 30px;
          }
        }

        button {
          @extend %hand-cursor-pointer2;
          font-size: 2rem;
          padding: 10px 30px;
          background-color: #ffffff;
          border-radius: 2px;

          @include for-small-screens {
            font-size: 1rem;
          }
        }
      }
    }

    .aboutText {
      font-size: 2px;

      .title {
        font-size: 3px;
        font-weight: bold;
      }
    }
  }
}
