@import '../shared/common.scss';

.Sequencer {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  user-select: none;

  .background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 0;

    .bolt {
      position: absolute;

      &.top {
        top: 10px;
      }

      &.bottom {
        bottom: 10px;
      }

      &.left {
        left: 10px;
      }

      &.right {
        right: 10px;
      }
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    z-index: 0;

    .header {
      width: 100%;

      .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 100px;
        padding-right: 100px;
        padding-top: 20px;
        flex-wrap: wrap;

        @include for-medium-screens {
          padding-left: 10px;
          padding-right: 10px;
        }

        @include for-medium-portrait-screens {
          padding-top: 40px;
        }

        @include for-small-screens {
          padding-left: 10px;
          padding-right: 10px;
        }

        &.small {
          display: none;
          height: 80px;
          justify-content: center;
          padding-top: 20px;

          @include for-medium-portrait-screens {
            display: flex;
          }

          @include for-small-screens {
            display: flex;
          }

          > * {
            height: 100%;
          }
        }

        > .left,
        > .right {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: space-around;
          height: 100%;
          box-sizing: border-box;

          > button {
            width: 60px;
            height: 70px;
            margin-left: 20px;
            margin-right: 20px;

            @include for-medium-screens {
              width: 50px;
              height: 60px;
              margin-left: 10px;
              margin-right: 10px;
            }

            @include for-small-screens {
              width: 40px;
              height: 50px;
              margin-left: 10px;
              margin-right: 10px;
            }

            @include for-medium-landscape-screens {
              width: 30px;
              margin-left: 10px;
              margin-right: 10px;
            }

            > svg {
              height: 51px;

              @include for-medium-screens {
                height: 41px;
              }

              @include for-small-screens {
                height: 35px;
              }

              @include for-medium-landscape-screens {
                height: 35px;
              }
            }
          }
        }

        > .center {
          flex: 1;
          min-width: 200px;

          @include for-medium-portrait-screens {
            display: none;
          }

          @include for-small-screens {
            display: none;
          }
        }
      }
    }
  }

  .scrollWrapper {
    height: 100%;
    overflow-x: auto;
  }

  .knobs {
    height: 100%;
    min-width: 100%;
    padding: 30px 100px 50px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include for-medium-screens {
      padding: 20px 20px 40px;
    }

    @include for-small-screens {
      padding: 10px 20px 40px;
    }

    .row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;

      &:not(:last-child) {
        margin-bottom: 15px;

        @include for-small-screens {
          margin-bottom: 10px;
        }

        @include for-medium-landscape-screens {
          margin-bottom: 0;
        }
      }
    }

    .controller {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      > :not(:last-child) {
        margin-bottom: 3px;
      }

      @include for-medium-screens {
        margin-right: 10px;
      }

      @include for-small-screens {
        margin-right: 10px;
      }
    }
  }
}
