@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@700&display=swap');

@import '../shared/common.scss';

@font-face {
  font-family: 'Hail';
  src: url('../assets/fonts/hail.woff2') format('woff2'),
    url('../assets/fonts/hail.woff') format('woff');
}

:global {
  html {
    font-size: 10px;

    @include for-small-screens {
      font-size: 7px;
    }

    @include for-medium-landscape-screens {
      font-size: 7px;
    }
  }

  html,
  body {
    margin: 0;
    padding: 0;
  }

  body {
    font-family: Hail, 'Amatic SC', cursive;
    overflow: hidden;
  }

  button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    -webkit-appearance: none;
    outline: none;
    font: inherit;
    color: inherit;
  }

  input {
    font: inherit;
  }

  #expanded-knob-container {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1;
  }

  .popupOverlayTransition-enter {
    opacity: 0;
  }
  .popupOverlayTransition-enter-active {
    opacity: 1;
    transition: opacity 300ms;
  }
  .popupOverlayTransition-exit {
    opacity: 1;
  }
  .popupOverlayTransition-exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }

  .popupTransition-enter {
    opacity: 0;
    transform: scale(0.8);
  }
  .popupTransition-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
  }
  .popupTransition-exit {
    opacity: 1;
  }
  .popupTransition-exit-active {
    opacity: 0;
    transform: scale(0.8);
    transition: opacity 300ms, transform 300ms;
  }

  .loadingTransition-exit {
    opacity: 1;
  }
  .loadingTransition-exit-active {
    opacity: 0;
    transition: opacity 500ms;
  }
}

.App {
  @extend %hand-cursor-default;
  position: relative;

  &.idle {
    cursor: none;
  }

  .layout {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .sequencerWrapper {
      width: 100%;
      height: 100%;
    }
  }
}
