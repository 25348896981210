@use 'sass:color';
@import '../shared/common.scss';

.Knob {
  position: relative;
  margin-right: 10px;
  height: 100%;
  max-height: 75px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-end;

  .normalKnob {
    @extend %hand-cursor-pointer1;
    transition: transform 0.2s ease-in-out;

    &:hover {
      transform: scale(1.1);
    }

    svg {
      height: 70px;

      @include for-medium-screens {
        height: 65px;
      }

      @include for-small-screens {
        height: 50px;
      }

      @include for-medium-landscape-screens {
        height: 40px;
      }

      width: auto;
      min-width: 40px;
      min-height: 40px;
      display: inline-block;

      g.rotateWrapper {
        transition: transform 0.2s ease-in-out;
        transform-origin: 50% 50%;
        margin: 0 10px;
      }

      g.lightWrapper {
        $light-off-ratio: 50%;

        path {
          &.DR {
            fill: scale-color($dr-color, $lightness: -$light-off-ratio);

            &.selected {
              fill: $dr-color;
            }
          }
          &.BS {
            fill: scale-color($bs-color, $lightness: -$light-off-ratio);

            &.selected {
              fill: $bs-color;
            }
          }
          &.MEL {
            fill: scale-color($mel-color, $lightness: -$light-off-ratio);

            &.selected {
              fill: $mel-color;
            }
          }
          &.FX {
            fill: scale-color($fx-color, $lightness: -$light-off-ratio);

            &.selected {
              fill: $fx-color;
            }
          }
          &.AMBIENT {
            fill: scale-color($ambient-color, $lightness: -$light-off-ratio);

            &.selected {
              fill: $ambient-color;
            }
          }
          &.TRADITIONAL {
            fill: scale-color(
              $traditional-color,
              $lightness: -$light-off-ratio
            );

            &.selected {
              fill: $traditional-color;
            }
          }
        }
      }
    }
  }

  .led {
    flex: none;
    width: 10px;
    height: 10px;
    border: 3px solid #000000;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: none;

    &.playing {
      background-color: #f00;
      box-shadow: 0 1px 6px #f00, inset 1px 1px 2px #300;
    }
  }
}
